<template>
  <nav
    class="navbar has-text-weight-bold"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        role="button"
        class="navbar-burger ml-0"
        aria-label="menu"
        :aria-expanded="isExpanded"
        :class="{ 'is-active': isExpanded }"
        @click="toggleExpand"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" :class="{ 'is-active': isExpanded }">
      <div class="navbar-start">
        <template v-for="item in MENU_DATA">
          <div
            v-if="typeof item.show === 'boolean' ? item.show : true"
            v-bind:key="item.title"
            class="navbar-item has-dropdown is-hoverable"
          >
            <a class="navbar-link">{{ item.title }}</a>
            <div class="navbar-dropdown">
              <template
                v-for="menuItem in item.items"
                v-bind:key="menuItem.title"
              >
                <router-link
                  :to="menuItem.href"
                  class="navbar-item"
                  v-if="
                    typeof menuItem.show === 'boolean' ? menuItem.show : true
                  "
                >
                  {{ menuItem.title }}
                </router-link>
              </template>
            </div>
          </div>
        </template>
      </div>
      <div class="navbar-end">
        <div class="navbar-item">
          <p v-if="team !== null" class="balance-block">
            Current balance:
            {{
              team.balanceCents
                ? `${team.balanceCents < 0 ? "- " : ""}$${
                    Math.abs(team.balanceCents) / 100
                  }`
                : "$0"
            }}
          </p>

          <div class="buttons">
            <button
              type="button"
              class="button is-danger"
              @click="auth.logoutOfAllDevices()"
            >
              Terminate all sessions
            </button>
            <button
              type="button"
              class="button is-light"
              @click="auth.logOut()"
            >
              <font-awesome-icon :icon="['fas', 'sign-out-alt']" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted } from "vue";
import Auth from "@/services/auth.service";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.store";
declare interface BaseComponentData {
  isExpanded: boolean;
}

declare interface MenuData {
  title: string;
  show?: boolean;
  items: {
    show?: boolean;
    href: string;
    title: string;
  }[];
}

export default defineComponent({
  inject: ["canManageUsers"],
  setup() {
    const userStore = useUserStore();

    const { team } = storeToRefs(userStore);

    onMounted(() => {
      userStore.getTeamInfo();
    });

    return {
      team,
      canManageUsers: inject("canManageUsers") as () => boolean,
    };
  },
  data(): BaseComponentData {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleExpand(): void {
      this.isExpanded = !this.isExpanded;
    },
  },
  computed: {
    auth() {
      return Auth;
    },
    isAdmin(): boolean {
      return this.canManageUsers();
    },
    MENU_DATA(): MenuData[] {
      return [
        {
          title: "Administration",
          show: this.isAdmin,
          items: [
            {
              href: "/admin/clean-up-data",
              title: "Clean up data",
            },
            {
              href: "/admin/ai",
              title: "AI",
            },
            {
              href: "/admin/workers",
              title: "Workers",
            },
            {
              href: "/admin/billing",
              title: "Teams billing",
            },
            {
              href: "/admin/accounts",
              title: "Accounts",
            },
            {
              href: "/admin/task-reports",
              title: "Task reports",
            },
            {
              href: "/admin/registration-reports",
              title: "Registration reports",
            },
          ],
        },
        {
          title: "Users",
          show: this.isAdmin,
          items: [
            {
              href: "/users",
              title: "List",
            },
            {
              href: "/users/create",
              title: "Create",
            },
          ],
        },
        {
          title: "Teams",
          items: [
            {
              href: "/teams",
              title: "List",
            },
            {
              show: this.isAdmin,
              href: "/teams/create",
              title: "Create",
            },
          ],
        },
        {
          title: "Tasks",
          items: [
            {
              href: "/tasks",
              title: "List",
            },
            {
              href: "/tasks/create",
              title: "Create",
            },
          ],
        },
        {
          title: "Templates",
          items: [
            {
              href: "/templates",
              title: "List",
            },
            {
              href: "/templates/create",
              title: "Create",
            },
          ],
        },
        {
          title: "Content list",
          items: [
            {
              href: "/content-library",
              title: "List",
            },
            {
              href: "/content-library/create",
              title: "Create",
            },
          ],
        },
      ];
    },
  },
});
</script>

<style scoped>
.balance-block {
  padding-right: 20px;
}
</style>
