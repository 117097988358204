import { RouteRecordRaw } from "vue-router";

export const admin: RouteRecordRaw = {
  path: "admin",
  name: "admin",
  component: () => import("@/views/Nested.vue"),
  children: [
    {
      path: "clean-up-data",
      name: "cleanUpDataForm",
      component: () => import("@/views/admin/ClearData.view.vue"),
    },
    {
      path: "ai",
      name: "AIForm",
      component: () => import("@/views/admin/AIConfig.view.vue"),
    },
    {
      path: "workers",
      name: "WorkersView",
      component: () => import("@/views/admin/Workers.view.vue"),
    },
    {
      path: "billing",
      name: "BillingView",
      component: () => import("@/views/admin/billing/Billing.view.vue"),
    },
    {
      path: "billing/:id/history",
      name: "BillingHistoryView",
      component: () => import("@/views/admin/billing/BillingHistory.view.vue"),
    },
    {
      path: "accounts",
      name: "AccountsView",
      component: () => import("@/views/admin/Accounts.view.vue"),
    },
    {
      path: "workers/:id",
      name: "WorkerDetailView",
      component: () => import("@/views/admin/WorkerDetail.view.vue"),
    },
    {
      path: "workers/:id",
      name: "WorkerDetailView",
      component: () => import("@/views/admin/WorkerDetail.view.vue"),
    },
    {
      path: "task-reports",
      name: "TaskReportsView",
      component: () => import("@/views/admin/TaskReports.view.vue"),
    },
    {
      path: "registration-reports",
      name: "RegistrationReportsView",
      component: () => import("@/views/admin/RegistrationReports.view.vue"),
    },
  ],
};
