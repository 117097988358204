import "@/assets/scss/index.scss";
import "vue-m-message/dist/style.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "@/App.vue";
import Message from "vue-m-message";
import router from "@/router";
import { abilitiesPlugin } from "@casl/vue";
import DayJsAdapter from "@date-io/dayjs";
import abilityService from "@/services/ability.service";

// Vuetify
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
  },
  locale: {
    locale: "ru-RU",
  },
});

// configure vee-validate
import { defineRule, configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import { required, min, max, numeric } from "@vee-validate/rules";
defineRule("required", required);
defineRule("min", min);
defineRule("max", max);
defineRule("numeric", numeric);

configure({
  // @ts-expect-error cant update vee-validate before rewrite form
  generateMessage: localize("en", {
    messages: {
      required: "Field is required",
      min: "Field should be minimum 0:{min} symbols length",
      max: "Field should be minimum 0:{max} symbols length",
      numeric: "Field should be numeric",
    },
  }),
});

// configure dayjs
import dayjs from "dayjs";
import DayJsCalendar from "dayjs/plugin/calendar";
import DayJsFormat from "dayjs/plugin/customParseFormat";
import DayJsRelativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(DayJsCalendar);
dayjs.extend(DayJsFormat);
dayjs.extend(DayJsRelativeTime);

import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import {
  faSignOutAlt,
  faLock,
  faEnvelope,
  faTrash,
  faCheck,
  faUndoAlt,
  faUpload,
  faHeart,
  faReply,
  faRecycle,
  faClone,
  faPencilAlt,
  faPlus,
  faBan,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Auth from "@/services/auth.service";

library.add(
  ...([
    faSignOutAlt,
    faLock,
    faEnvelope,
    faTrash,
    faCheck,
    faUndoAlt,
    faUpload,
    faHeart,
    faReply,
    faRecycle,
    faClone,
    faPencilAlt,
    faPlus,
    faBan,
    faLink,
  ] as IconDefinition[]),
);

(Auth.isLoggedIn ? Auth.getSession() : Promise.resolve()).finally(() => {
  createApp(App)
    .use(createPinia())
    .use(Message)
    .use(router)
    .use(vuetify)
    .use(abilitiesPlugin, abilityService.abilities)
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app");
});
